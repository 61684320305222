import { json } from '@remix-run/node'
import { Form, useLoaderData } from '@remix-run/react'

import { Button } from '~/components/Button'
import { CopyrightPrivacyPolicy } from '~/components/CopyrightPrivacyPolicy'
import { Wireframe } from '~/components/wireframe'
import type { MyLoaderArgs } from '~/container'
import { $path } from '~/utils/route-helpers'

export const loader = async ({ request, context: { cnt } }: MyLoaderArgs) => {
  let session = await cnt.services.session.storage.getSession(request.headers.get('cookie'))
  let error = session.get(cnt.services.authN.authenticator.sessionErrorKey)
  return json({ error })
}

export default function Page() {
  const data = useLoaderData<typeof loader>()
  return (
    <Wireframe>
      {data.error && <div className='mb-8 bg-error-25 p-2 text-error-700'>{data.error.message}</div>}
      <h1>Sign in</h1>
      <br />
      <Form method='POST' action={$path('/auth/okta')} className='flex justify-center'>
        <Button type='submit' data-test-id='sign-in'>
          Sign in
        </Button>
      </Form>
      <CopyrightPrivacyPolicy className='absolute bottom-4 left-1/2 -translate-x-1/2' />
    </Wireframe>
  )
}
